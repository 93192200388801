import React from "react";
import { GatsbyImage as Image } from "gatsby-plugin-image";

export default function SplashImage({ image }) {
  return (
    <div className="splash-image">
      <Image image={image} alt="Splash Image" style={{ height: "400px" }} />
    </div>
  );
}
